@font-face {
    font-family: 'file';
    src: url('../fonts/icono/file.eot?74132827');
    src: url('../fonts/icono/file.eot?74132827#iefix') format('embedded-opentype'), url('../fonts/icono/file.woff2?74132827') format('woff2'), url('../fonts/icono/file.woff?74132827') format('woff'), url('../fonts/icono/file.ttf?74132827') format('truetype'), url('../fonts/icono/file.svg?74132827#file') format('svg');
    font-weight: normal;
    font-style: normal;
}


/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */


/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */


/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'file';
    src: url('../font/file.svg?74132827#file') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "file";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-folder:before {
    content: '\e800';
}

.icon-004-img:before {
    content: '\e801';
}

.icon-002-doc:before {
    content: '\e802';
}

.icon-003-zip:before {
    content: '\e803';
}

.icon-001-pdf:before {
    content: '\e804';
}
