@import './styles.css';
@import './assets/css/file.css';
*:focus {
    outline: 0;
}

html {
    height: 100%;
}

body {
    font-family: 'Nunito', sans-serif;
    height: 100%;
    margin: 0;
    overflow-x: hidden !important;
}

code {
    /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
    font-family: 'Nunito', sans-serif;
}

#root,
#root>div {
    height: 100%;
}